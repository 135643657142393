import React from 'react'
import { Layout, Col, Row, Image, Typography, Button, Space } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';

import './features.less'
const { Content } = Layout;
const { Title, Paragraph, Text, Link } = Typography;

export default function Features() {
  return (
    <Content className='features-root'>
      <Col className='features-container' >
        <Row style={{ alignItems: 'center' }}>
          <Typography>
            <Title className="features-title">Features</Title>
            <Paragraph className='features-subtitle'>
              Realtime and easy reporting of tasks by visiting client location, through your custom forms.
            </Paragraph>
          </Typography>
        </Row>
      </Col>
    </Content>
  )
}